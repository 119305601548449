<template>
  <div class="tag-details">
    <Header />
    <el-main>
      <el-row justify="center" align="middle">
        <el-col :xs="23" :sm="20" :md="18" :lg="14" :xl="10">
          <el-breadcrumb separator="/" class="animated fadeInDown">
            <span style="float: left">当前位置：</span>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/tags' }"
              >标签</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ tag.name }}</el-breadcrumb-item>
          </el-breadcrumb>
          <article
                  v-for="(item, index) in articles"
                  :key="index"
                  class="main-article fadeInUp animated visible"
          >
            <div class="label">{{ $route.params.name }}</div>
            <a
                    class="cover"
                    href="javascript: void(0)"
                    @click="linkToArticle(item)"
            >
              <el-image :src="item.coverUrl">
                <template #placeholder>
                  <img style="width: 100%" src="@/assets/image/loading.gif" />
                </template>
                <template #error>
                  <div class="image-slot">
                    <i class="fa fa-image"></i>
                  </div>
                </template>
              </el-image>
            </a>
            <div class="info">
              <div class="date">
                <div class="date-icon">
                  <i class="iconfont icon-rili"></i>
                </div>
                <span>{{ item.publishDate }}</span>
              </div>
              <h3>
                <a href="javascript: void(0)" @click="linkToArticle(item)">
                  {{ item.title }}
                </a>
              </h3>
              <div class="Introduction">
                {{ item.summary }}
              </div>
              <div class="meta">
                <div class="date-icon meta-browse">
                  <i class="iconfont icon-remen"></i>
                </div>
                <span>{{ item.readNum }} 浏览</span>
                <div class="date-icon meta-label">
                  <i class="iconfont icon-Icon_Bo-"></i>
                </div>
                <span>{{ item.praiseNum }} 点赞</span>
                <div class="date-icon meta-type">
                  <i class="iconfont icon-pinglun"></i>
                </div>
                <span>{{ item.commentNum }} 评论</span>
              </div>
              <a href="javascript: void(0)" @click="linkToArticle(item)" class="btn"
              >阅读全文</a
              >
            </div>
          </article>
          <div class="pagination fadeInUp animated visible">
            <el-pagination
                    background
                    layout="pager, prev, jumper, next"
                    @current-change="handleCurrentChange"
                    :current-page="page.curPage"
                    :total="page.pageCount"
                    :page-size="page.pageSize"
                    :pager-count="5"
                    v-show="page.pageCount > page.pageSize"
            ></el-pagination>
          </div>
          <el-empty
            v-show="articles.length == 0"
            description="没有找到文章数据"
          />
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import { getTag } from "@/api/tag";
import Header from "@/components/common/header";
export default {
  name: "details",
  components: {
    Header,
  },
  data() {
    return {
      tag: {},
      articles: [],
      page: {
        curPage: 1,
        pageSize: Number(this.$store.state.settings.configs.articlePageSize),
        pageCount: 0,
      },
    };
  },
  created() {
    this.getTag();
  },
  methods: {
    async getTag() {
      const name = this.$route.params.name;
      this.page.name = name;
      await getTag(this.page).then((res) => {
        if (res.code == 200) {
          let tag = res.data.tag;
          this.tag = tag;
          this.page.pageCount = tag.count;
          this.articles = res.data.articles;
        }
      });
      this.initPage();
    },
    linkToArticle(item) {
      if (item.customLink) {
        this.$router.push("/article/" + item.customLink + ".html");
      } else {
        this.$router.push("/article/" + item.link);
      }
    },
    handleCurrentChange: function (currentPage) {
      //回到最顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      //修改页数
      this.page.curPage = currentPage;
      this.getTag();
      //第一页隐藏上一页按钮
      if (currentPage == 1) {
        document.getElementsByClassName("btn-prev")[0].classList.add("hidden");
      } else {
        document
          .getElementsByClassName("btn-prev")[0]
          .classList.remove("hidden");
      }
      if (currentPage == Math.ceil(this.page.pageCount / this.page.pageSize)) {
        document.getElementsByClassName("btn-next")[0].classList.add("hidden");
      } else {
        document
          .getElementsByClassName("btn-next")[0]
          .classList.remove("hidden");
      }
    },
    initPage() {
      if (this.page.curPage == 1) {
        document.getElementsByClassName("btn-prev")[0].classList.add("hidden");
      } else {
        document
          .getElementsByClassName("btn-prev")[0]
          .classList.remove("hidden");
      }
      if (
        this.page.curPage == Math.ceil(this.page.pageCount / this.page.pageSize)
      ) {
        document.getElementsByClassName("btn-next")[0].classList.add("hidden");
      } else {
        document
          .getElementsByClassName("btn-next")[0]
          .classList.remove("hidden");
      }
    },
  },
};
</script>

<style scoped>
.el-header {
  position: relative;
  margin-bottom: 1rem;
  object-fit: cover;
  border: none;
  filter: brightness(0.9);
  height: 100%;
}
.el-main >>> span,
div {
  color: var(--text-color);
}
tag-details {
  width: 100%;
  height: 100%;
  color: var(--text-color);
}
.tag-details .el-header .cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  border: none;
}
.tag-details .el-header .cover .el-image {
  width: 100%;
  height: 30rem;
  object-fit: cover;
  border: none;
}
.tag-details .el-header .brand {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  height: 30rem;
  text-shadow: 0 0.1875rem 0.3125rem #1c1f21;
  opacity: 0;
  border: none;
}
.tag-details .el-header .brand p {
  font-size: 18px;
}
.tag-details .el-header .brand p.title {
  position: relative;
  font-family: "BiaoSong", sans-serif;
  font-size: 3.2em;
  line-height: 1.2;
  z-index: 100;
}
.tag-details .el-header .title span {
  display: inline-block;
  animate-duration: 2s;
}

.el-breadcrumb {
  padding: 20px;
}
.tag-details .pagination {
  width: 100%;
  padding: 1.25rem 0;
  text-align: center;
  display: inline-block;
  opacity: 0;
}
.main-article {
  position: relative;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 1px 30px -10px var(--shadow-color);
  transition: all 0.5s;
  height: 300px;
  margin: 1rem 0 2rem 0;
  background: rgba(0, 0, 0, 0);
  opacity: 0;
}
.main-article:hover {
  box-shadow: 0 1px 20px -4px var(--shadow-hover-color);
}
.main-article:nth-child(even) {
  flex-direction: row-reverse;
}
.main-article .label {
  position: absolute;
  top: 20px;
  left: -16px;
  padding: 0.8rem;
  color: #fff;
  background-color: var(--primary-color);
  box-shadow: 0 1px 5px var(--primary-color);
  border-radius: 0 4px 4px 0;
  letter-spacing: 4px;
  z-index: 10;
  font-size: 14px;
  font-family: "Microsoft YaHei", sans-serif;
}
.main-article:nth-child(even) .label {
  left: unset;
  right: -16px;
  border-radius: 4px 0 0 4px;
}
.main-article .label::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-width: 0 1rem 1rem 0;
  border-color: transparent;
  border-right-color: var(--primary-color);
  filter: brightness(0.9);
}
.main-article:nth-child(even) .label::before {
  left: unset;
  right: 0;
  transform: rotate(-90deg);
}
.main-article .cover {
  width: 50%;
  margin-right: 1.5rem;
  clip-path: polygon(0 0, 92% 0, 100% 100%, 0 100%);
  border-radius: 0.625rem 0 0 0.625rem;
  overflow: hidden;
  height: 300px;
}
.main-article:nth-child(even) .cover {
  margin-right: auto;
  margin-left: 1.5rem;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 8% 100%);
  border-radius: 0 0.625rem 0.625rem 0;
}
.main-article .cover .el-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out 0s;
  overflow: hidden;
}
.main-article:hover .cover .el-image {
  transform: scale(1.05) rotate(1deg);
}
.main-article .info {
  position: relative;
  width: 50%;
  padding: 1rem 1.5rem 3rem 1rem;
  perspective: 62.5rem;
}
.main-article .info .date {
  float: right;
  font-size: 12px;
  color: #ccc;
}
.main-article .info .date span {
  color: var(--text-color);
}
.main-article:nth-child(even) .info .date {
  float: left;
}
.main-article .info .date-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  background: #3a8ee5;
  color: #000;
  margin: 0 5px;
  font-size: 14px;
  line-height: 20px;
}
.main-article .info .date-icon i {
  padding: 0;
}
.main-article .info h3 {
  margin: 2rem 0 0 0;
  color: #000;
  font-size: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  clear: both;
  padding-left: 2px;
  font-weight: 700;
  line-height: 1.5;
  font-family: "BiaoSong", sans-serif;
}
.main-article .info h3 > a {
  text-decoration: none;
}
.main-article .info h3 > a:hover {
  color: var(--primary-color);
}
.main-article .info .Introduction {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 6rem;
  margin-top: 1rem;
  text-align: justify;
  line-height: 1.6rem;
  opacity: 0.9;
  color: var(--text-color);
}
.main-article .info .meta {
  font-size: 12px;
  color: var(--text-color);
  margin-top: 1rem;
}
.main-article .info .meta .meta-browse {
  background: #e9495b;
}
.main-article .info .meta .meta-label {
  background: #ff7744;
}
.main-article .info .meta .meta-type {
  background: #3cd38e;
}
.main-article .info > a {
  font-size: 14px;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform-style: preserve-3d;
  transform: translateZ(2rem);
  padding: 0.5rem 1rem;
  border-radius: 1rem 0;
  background: var(--primary-color);
  box-shadow: 0 1px 12px -4px var(--primary-color);
  color: #fff;
  letter-spacing: 2px;
}
.main-article .info > a:hover {
  box-shadow: 0 1px 20px -4px var(--primary-color);
}
.main-article:nth-child(even) .info > a {
  left: 0;
  right: auto;
  border-radius: 0 1rem;
  background: var(--primary-color);
  color: #fff;
}
.main-container .pagination {
  width: 100%;
  padding: 1.25rem 0;
  text-align: center;
  display: inline-block;
  opacity: 0;
}
@media screen and (max-width: 768px) {
  .main-article .info {
    width: 100%;
    height: 14rem;
    padding: 0;
  }
  .main-article {
    flex-direction: column;
    height: fit-content;
    max-height: fit-content;
  }
  .main-article .cover {
    width: 100%;
    height: 14rem;
    margin: auto;
    clip-path: polygon(0 0, 100% 0, 100% 92%, 0 100%);
    border-radius: 0.625rem 0.625rem 0 0;
  }
  .main-article:nth-child(even) {
    flex-direction: column;
  }
  .main-article:nth-child(even) .cover {
    width: 100%;
    margin: auto;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);
    border-radius: 0.625rem 0.625rem 0 0;
  }
  .main-article:nth-child(even) .info .meta {
    float: right;
    padding: 0 5px;
  }
  .main-article .info .Introduction {
    padding: 0 10px;
  }
  .main-article .info h3 {
    padding-left: 10px;
  }
}
</style>
